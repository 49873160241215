import { CSSProperties, PropsWithChildren, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { SxProps, Theme } from '@mui/material';

interface DialogProps {
  noPadding?: boolean;
  className?: string;
  withClose?: boolean;
  title?: string;
  titleSx?: SxProps<Theme>;
  fullWidth?: boolean;
  width?: number | string;
  isOpen: boolean;
  handleClose?: () => void;
  paperStyles?: CSSProperties;
  CustomCloseIcon?: React.FC<React.SVGProps<SVGSVGElement>> | any;
  transitionStyles?: CSSProperties;
  paperSx?: SxProps<Theme>;
  dialogStyles?: CSSProperties;
  id?: string;
}

export default function Modal(props: DialogProps & PropsWithChildren) {
  const {
    title,
    children,
    isOpen,
    fullWidth,
    width = 320,
    paperStyles = {},
    titleSx = {},
    paperSx,
    noPadding = false,
    withClose = false,
    CustomCloseIcon,
    handleClose,
    className,
    transitionStyles = {},
    id,
    dialogStyles,
  } = props;

  return (
    <Dialog
      id={id}
      fullWidth={fullWidth}
      open={isOpen}
      onClose={handleClose}
      data-modal
      style={dialogStyles}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      TransitionProps={{
        style: {
          ...transitionStyles,
        },
      }}
      PaperProps={{
        style: {
          borderRadius: 16,
          width: fullWidth ? undefined : width,
          maxWidth: fullWidth ? undefined : width,
          ...paperStyles,
        },
        sx: paperSx,
      }}
    >
      {withClose ? (
        <Box
          onClick={handleClose}
          sx={{
            borderRadius: 2,
            position: 'absolute',
            ['html:not([dir="rtl"]) &']: {
              right: 15,
            },
            ['[dir="rtl"] &']: {
              left: 15,
            },
            top: 15,
            width: 28,
            height: 28,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          {CustomCloseIcon ? <CustomCloseIcon /> : <CloseIcon />}
        </Box>
      ) : null}
      {!title ? null : (
        <DialogTitle id="dialog-title" sx={titleSx}>
          {title}
        </DialogTitle>
      )}
      <DialogContent
        className={className}
        sx={(theme) => ({
          padding: noPadding ? 0 : undefined,
          width: fullWidth ? undefined : width,
          maxWidth: fullWidth ? undefined : width,
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        })}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}
