import { request } from 'src/lib/request';
import { isSuccess, RemoteDataResult, success } from 'src/lib/remoteData';

import { SignupBody } from './AuthService';
import * as CookieSyncService from 'src/services/CookieSyncService';

export type ProfileBody = Pick<
  SignupBody,
  'first_name' | 'last_name' | 'birth_date' | 'phone' | 'postcode'
>;

export type ProfileFields = Pick<
  SignupBody,
  'phone' | 'postcode' | 'address' | 'city'
>;

export type LanguageBody = Pick<SignupBody, 'language'>;

export function updateProfileFields(
  data: ProfileFields
): Promise<RemoteDataResult<Profile>> {
  return request({
    url: 'v1/profile/',
    method: 'PUT',
    data: JSON.stringify(data),
  });
}

export function updateLanguage(
  data: LanguageBody
): Promise<RemoteDataResult<Profile>> {
  return request({
    url: 'v1/profile/language',
    method: 'PATCH',
    data: JSON.stringify(data),
  });
}

async function fetchProfileAndUpdateCookie(
  url: string,
  method: 'GET' | 'PATCH',
  data?: ProfileBody
): Promise<RemoteDataResult<ProfileUI>> {
  const profileResponse = request({
    url: url,
    method: method,
    ...(data && { data: JSON.stringify(data) }),
  });
  const syncResponse = CookieSyncService.sync();
  const [profile, sync] = await Promise.all([profileResponse, syncResponse]);

  if (isSuccess(profile) && isSuccess(sync)) {
    return success({ ...profile.data, oaid: sync.data.gid });
  }

  return { ...profile };
}

export function updateProfile(
  data: ProfileBody
): Promise<RemoteDataResult<ProfileUI>> {
  return fetchProfileAndUpdateCookie('v1/profile/', 'PATCH', data);
}

export function getProfile(): Promise<RemoteDataResult<ProfileUI>> {
  return fetchProfileAndUpdateCookie('v1/profile/', 'GET');
}

export function updateAvatar(): Promise<RemoteDataResult<Profile>> {
  return request({
    url: 'v1/profile/avatar',
    method: 'PATCH',
  });
}

export function ip(): Promise<RemoteDataResult<{ ip: string }>> {
  return request({
    url: 'v1/profile/ip',
    method: 'GET',
  });
}

export const updateCryptoWallet = (address: string) => {
  return request({
    url: 'v1/profile/crypto-wallet-address',
    method: 'PATCH',
    data: JSON.stringify({ address }),
  });
};
